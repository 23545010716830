<template></template>
<script>
import { h } from 'vue';
import videoPlayerPack from "@/components/video/index.vue";
import dayjs from "dayjs";

export default {
    name: "alarm_notification",
    data() {
        return {
            notifications: [],
            post: false,
            // sound: this.$store.getters.userinfo.owner?.extend,
        };
    },
    watch: {
        "$store.state.socket.alarmBoxData": function (val) {
            this.showNotification(val);
        },
    },
    components: {
        videoPlayerPack,
    },
    methods: {
        concatCustomImages(list) {
            return list.map(img => {
                return h(
                    "div",
                    {
                        class: ["alarm-custom", "alarm-image"],
                    },
                    [
                        h(
                            "img",
                            {
                                attrs: {
                                    src: img
                                }
                            },
                        )
                    ]
                )
            });
        },
        concatCustomVideos(list) {
            return list.map(data => {
                return h(
                    "div",
                    {
                        class: ["alarm-custom", 'alarm-video'],
                    },
                    [
                        h(
                            videoPlayerPack,
                            {
                                data,
                            }
                        )
                    ]
                );
            });
        },
        async getNoticeData(data) {
            this.showNotification(data);
        },
        async showNotification(data) {
            // const sound = this.$store.getters.userinfo.owner?.extend;
            // if (sound == 1) {
            //     const audio = document.createElement('audio');
            //     document.documentElement.appendChild(audio);
            //     audio.src = '/audio/alarm.mp3';
            //     audio.addEventListener('canplay', () => {
            //         audio.play();
            //     })
            //     audio.addEventListener('ended', () => {
            //         audio.remove();
            //     })
            // }
            const htmlContent = h(
                "div",
                {
                    class: ["html-content", 'alarm-notifications-box'],
                },
                [
                    h("div", [
                        `${dayjs().format(
                            "YYYY-MM-DD HH:mm:ss"
                        )}`,
                    ]),
                    h("div", [ `${data?.content ?? ''}`, ]),
                    data.images && Array.isArray(data.images) ? this.concatCustomImages(data.images) : '',
                    data.videos && Array.isArray(data.videos) ? this.concatCustomVideos(data.videos) : '',
                ]
            );
            let params = {
                title: data.title,
                duration: data.videos?.length > 0 ? 30000 : 5000,
                offset: 70,
                message: htmlContent,
            };
            this.$nextTick(() => {
                this.$notify(params);
            })
        },
    },
    // mounted() {
    //     this.getNoticeData();
    // },
};
</script>

<style lang="scss">
audio {
    display: none;
}
.el-notification {
    --el-notification-padding: 14px 8px;
    --el-notification-group-margin-left: 7px;
    --el-notification-group-margin-right: 7px;
}
.alarm-notifications-box {
    &.html-content {
        width: 300px;
        .alarm-custom {
            margin-top: 10px;
            height: 200px;
            width: 100%;
            position: relative;
            img {
                position: absolute;
                top: 0;
                left: 50%;
                height: 100%;
                transform: translate(-50%, 0);
            }
        }
    }
}
</style>
