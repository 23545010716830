<template>
    <div class="video-window-item" ref="video-wrapper">
        <template v-if="basic.data && basic.show_flag">
            <Player :data="basic.data" @closeWS="fn.closeWS" />
        </template>
        <div class="video-window-item-novideo" v-else>{{ basic.videoError ? '无法获取视频' : '未获取视频' }}</div>
    </div>
</template>
<script>
import Player from './player.vue';
import { getToken } from '@/utils/tools/auth';
import { dynamicFrontRequest } from '@/utils/api/common';

export default {
    name: "videoWindowItem",
    components: {
        Player,
    },
};
</script>
<script setup>
// import { reactive, nextTick, toRefs, watch, onBeforeUnmount, onUnmounted } from 'vue';

const props = defineProps({
    data: {
        type: Object,
        default: () => {}
    },
    win_num: {
        type: Number,
        default: 0,
    },
});
const { data } = toRefs(props);

const basic = reactive({
    show_flag: false,
    videoError: false,
    width: "",
    height: "",
    data: null,
    ws: {
        outer: {
            t: null,
            ws: null,
            url: null,
            connect: false,
        },
        inter: {
            t: null,
            ws: null,
            url: null,
            connect: false,
        }
    },
})
const fn = {
    sendTokenToLogin(ws) {
        fn.sendSocketMsg(ws, JSON.stringify({
            "msgType": "Authorization",
            "parameter": getToken(),
        }));
    },
    // fetchVideoUrlFromWS(info) {
    //     fn.sendSocketMsg(JSON.stringify({
    //         "msgType": "Message",
    //         "parameter": {
    //             videoId: info.id,
    //         }
    //     }));
    // },
    sendSocketMsg(ws, message) {
        ws.send(message);
    },
    wsMiddleware(ws, info, flag, { resolve, reject }) {
        if (ws) {
            basic.ws[flag].ws = ws;
        } else {
            return;
        }
        ws.onopen = function (e) {
            console.log('ws connect, device code: ' + info.code);
            fn.sendTokenToLogin(ws);
            fn.clearTimer(flag);
            basic.ws[flag].connect = true;
            basic.ws[flag].t = setInterval(() => {
                fn.sendSocketMsg(ws, 'ping');
            }, 1000 * 5);
            if (flag == 'inter') {
                fn.resetVideo('outer');
            }
            resolve('');
        }
        
        ws.onmessage = function (e) {
            try {
                const data = JSON.parse(e.data);
                if (data.code != 0) {
                    basic.videoError = true;
                } else if (data.code == 0 && data.data) {
                    basic.data = {
                        videoShowType: 'flv',
                        flv: data.data,
                        random: Math.random(),
                    }
                    fn.data_change();
                }
            } catch (error) {
                
            }
        }
        ws.onerror = function (e) {
            console.log('ws error ' + flag + ':', e);
            fn.clearTimer(flag);
            reject('')
        }
        ws.onclose = function (e) {
            console.log('ws close ' + flag + ': ', e);
            fn.clearTimer(flag);
            if (e.code != 3344) {
                if (
                    (flag == 'inter' && !basic.ws.outer.connect) ||
                    (flag == 'outer' && !basic.ws.inter.connect)
                ) {
                    fn.checkWebcoketConnect(info, false);
                }
            }
        }
    },
    checkInterWebsocket(info, d) {
        const ws = new WebSocket(`${info.interVideoServerIp}`);
        fn.wsMiddleware(ws, info, 'inter', d);
    },
    checkOuterWebsocket(info, d) {
        const ws = new WebSocket(`${basic.ws.outer.url}/${info.code}`);
        fn.wsMiddleware(ws, info, 'outer', d);
    },
    checkWebcoketConnect(info, flag = true) {
        return new Promise((resolve, reject) => {
            fn.checkOuterWebsocket(info, { resolve, reject })
            if (flag && info.inter) {
                fn.checkInterWebsocket(info, { resolve, reject });
            }
        })
    },
    data_change() {
        basic.show_flag = false;
        nextTick(() => {
            basic.show_flag = true;
        })
    },
    clearTimer(flag) {
        if (flag) {
            basic.ws[flag].t && clearInterval(basic.ws[flag].t);
            basic.ws[flag].t = null;
        } else {
            const keys = Object.keys(basic.ws);
            keys.forEach(k => {
                basic.ws[k].t && clearInterval(basic.ws[k].t);
            })
        }
    },
    closeWS(flag) {
        if (flag) {
            console.log('close ws ' + flag)
            basic.ws[flag].ws && basic.ws[flag].ws.close(3344);
            basic.ws[flag].connect = false;
        } else {
            const keys = Object.keys(basic.ws);
            keys.forEach(k => {
                basic.ws[k].ws && basic.ws[k].ws.close(3344);
                basic.ws[k].connect = false;
            })
        }
    },
    resetVideo(flag) {
        console.log('reset video ', flag)
        fn.clearTimer(flag);
        fn.closeWS(flag);
    }
}
watch(data, val => {
    if (val.inter && val.interVideoServerIp) {
        // 如果设置了内网且有内网ws地址，先赋值
        basic.ws.inter.url = val.interVideoServerIp;
    }
    if (basic.ws.outer.url) {
        fn.clearTimer();
        fn.checkWebcoketConnect(val).catch(err => err);
    } else {
        fn.resetVideo();
        dynamicFrontRequest({
            api: '/config/index.json',
            method: 'GET',
        }).then(res => {
            if (res.code == 0) {
                // 赋值外网ws地址
                basic.ws.outer.url = res.data.VIDEO_WS;
                fn.checkWebcoketConnect(val).catch(err => err);
            }
        })
        
    }
}, {
    immediate: true,
})
onUnmounted(() => {
    fn.resetVideo();
})

</script>

<style lang="scss" scoped >
:deep(.video-js) {
    width: 100%;
    height: 100%;
}

.vjs-custom-skin {
    width: 100%;
    height: 100%;
}

.video-window-item {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.7);
    position: relative;
    background: #111111;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;

    .player-wrapper {
        width: 100%;
    }

    iframe {
        border: none;
    }

    .video-window-item-novideo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 50px;
        text-align: center;
    }
}

.video-window-item-active {
    border: 1px solid #0051fd;
}
</style>
