import axios from 'axios';

const service = axios.create({
    baseURL: '/',
    // withCredentials: true,
    timeout: 50000
})
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return Promise.reject(error);
    }
)
service.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error)
    }
)

const headerConfig = {
    json: {
        "Content-Type": 'application/json'
    },
    urlencoded: {
        "Content-Type": 'application/x-www-form-urlencoded'
    },
    multipart: {
        "Content-Type": 'multipart/form-data'
    },
    upload: {
        "Content-Type": 'multipart/form-data1;boundary = ' + new Date().getTime()
    }
}
const commonRequest = (type, params, header) => {
    const obj = {
        method: type,
    }
    if (['get'].findIndex(u => u == type) > -1) {
        obj.params = params;
    } else {
        obj.data = params;
    }
    if (header) {
        obj.headers = Object.assign({}, headerConfig[header])
    }
    return obj;

}
const requestMethods = {
    get: (params) => commonRequest('get', params),
    patch: (params) => commonRequest('patch', params, 'json'),
    patchBody: (params) => commonRequest('patch', params, 'json'),
    postFormData: (params) => commonRequest('post', params, 'multipart'),
    post: (params) => commonRequest('post', params, 'json'),
    put: (params) => commonRequest('put', params, 'json'),
    putJSON: (params) => commonRequest('put', params, 'json'),
    putFormData: (params) => commonRequest('put', params, 'multipart'),
    delete: (params) => commonRequest('delete', params, 'json'),
    upload: (params) => commonRequest('post', params, 'upload'),
    exportPost: (params) => commonRequest('post', params, 'json'),
    // deleteOriginal: (params) => commonRequest('delete', params),
    // postOrignal(data = {}) {
    //     let requestParams = new URLSearchParams();
    //     for (let [k, v] of Object.entries(data)) {
    //         requestParams.append(k, v);
    //     }
    //     return {
    //         method: 'post',
    //         data: requestParams,
    //         headers: Object.assign({}, headerConfig.urlencoded)
    //     }
    // },
}

const _request = (method, url, params, options = {}) => {
    Object.assign(options, {
        url: url
    })
    if (requestMethods[method]) {
        const met = requestMethods[method];
        Object.assign(options, met(params, options));
    } else {
        // 默认get请求
        Object.assign(options, requestMethods.get(params, options));
    }
    return service(options)
}

const methodKeys = Object.keys(requestMethods);

let requestCommon = {
    all() {
        return Promise.all([...arguments]);
    }
};
methodKeys.forEach(m => {
    requestCommon[m] = (url, params, options) => {
        return _request(m, url, params, options)
    }
})
export default requestCommon;
