import T from '@/utils/tools/request';
// 前端动态接口
export const dynamicFrontRequest = async (data) => {
    if (typeof data == 'string') {
        data = {
            method: 'get',
            api: data,
        }
    }
    let { method, api, params, options } = data;
    method = method?.toLowerCase();
    if (method && T[method]) {
        const res = await T[method](api, params, options);
        return {
            code: res ? 0 : 500,
            data: res,
        }
    }
    return {
        code: 404
    }
};