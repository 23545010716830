<template>
    <section style="width: 100%; height: 100%">
        <template v-if="info.showVideoBox">
            <iframe class="video-iframe" v-if="info.videoType == 'iframe'" :src="info.url" frameborder="0" width="100%"
                height="100%" allowfullscreen></iframe>
            <iframe class="video-web" v-else-if="info.videoType == 'web'" :src="info.url" frameborder="0" width="100%"
                height="100%" allowfullscreen></iframe>
            <template v-else-if="info.videoType == 'hls'">
                <section ref="player" style="position: relative; width: 100%; height: 100%"></section>
            </template>
            <section v-else-if="info.videoType == 'flv'" class="width-full height-full" style="text-align:center">
                <video id="video-flv" controls ref="flv_player" style="max-width:100%;height:100%;"></video>
            </section>
        </template>
        <template v-else>正在读取视频流</template>
    </section>
</template>
<style scoped lang='scss'>
.easy-player {
    overflow: hidden;
}
</style>
<style lang="scss">
.vjs-control-text {
    display: none;
}
</style>

<script setup>
// import {
//     ref,
//     reactive,
//     onMounted,
//     onUnmounted,
//     nextTick,
//     watch,
//     toRefs,
// } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const player = ref(null);
const flv_player = ref(null);
const props = defineProps({
    data: {
        type: Object,
        default: () => { },
    },
});
const { data } = toRefs(props);
const info = reactive({
    url: "",
    isHttp: false,
    host: "",
    showVideoBox: true,
    videoType: '',
    t: null,
    flvPlayer: null,
    hlsPlayer: null,
});
const fn = {
    initPlayer() {
        // if (info.videoType == 'flv') {
        if (flvjs.isSupported()) {
            // var videoElement = document.getElementById('video-flv');
            const flvPlayer = flvjs.createPlayer({
                type: info.videoType,
                url: info.url,
                isLive: true,
            });
            flvPlayer.attachMediaElement(flv_player.value);
            flvPlayer.load();
            flvPlayer.play();
            info.flvPlayer = flvPlayer;
        }
        // } else {
        //     player.value && (player.value.innerHTML = `<video muted id="videojs-${id}" style="width:100%;height:100%"></video>`);
        //     nextTick(() => {
        //         try {
        //             info.hlsPlayer && info.hlsPlayer.dispose();
        //             info.hlsPlayer = videojs('videojs-' + id);
        //             info.hlsPlayer.src(info.url);
        //             info.hlsPlayer.play();
        //         } catch (error) {
        //             console.log(error)
        //         }
        //     })
        // }
    },
    checkUrl(video) {
        info.videoType = route.query.video_type || video?.videoShowType || 'hls';
        info.url = route.query.video_url || video?.[info.videoType];
        // if (info.url?.indexOf("http://") > 1) {
        //     info.isHttp = true;
        //     return;
        // }
        info.isHttp = false;
        if (info.url) {
            nextTick(() => {
                fn.initPlayer();
            })
        }
    },
    init(video) {
        info.showVideoBox = true;
        fn.checkUrl(video);
    },
    checkTime() {
        info.t && clearInterval(info.t);
        info.t = setInterval(() => {
            info.showVideoBox = false;
            nextTick(() => {
                fn.init();
            });
        }, 1000 * 60 * 60 * 12);
    },
    destroy() {
        if (info.flvPlayer) {
            info.flvPlayer.pause();
            info.flvPlayer.unload();
            info.flvPlayer.detachMediaElement();
            info.flvPlayer.destroy();
        }
    }
};

onUnmounted(() => {
    fn.destroy();
})
// watch(() => store.state.socket.videoReload, val => {
//     info.showVideoBox = false;
//     nextTick(() => {
//         fn.init(data.value);
//     })
// })
watch(data, val => {
    fn.init(val);
    fn.checkTime();
}, {
    deep: true,
    immediate: true,
})
</script>